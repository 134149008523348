import React from "react";
import {
  SEO,
  PageLayout,
  OutboundLink,
  HeroElement,
  Content,
  CallToActionBanner,
  InternalLink,
  Call,
  Image,
  Background,
  ContinuousScroll,
  PatternedImageRow,
  BasicCarousel,
  componentIterator,
  Review,
} from "@bluefin/components";
import { Grid, Header, Button } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles, getPageHeroImage } from "../utils/utils";

export default class IndexPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout className={"home-page"}>
          <div className={"hero-images-container"}>
            <OutboundLink to={"https://ashercaffe.square.site/"}>
              <HeroElement
                header={"Welcome to Asher Caffe"}
                tagline={
                  <div>
                    <p>Glatt Kosher</p>
                  </div>
                }
                images={getPageHeroImage({
                  options: fishermanBusinessWebsitePage.components,
                })}
                ctas={[]}
                ctaInverted={true}
                ctaColor={"white"}
                containerColor={"primary"}
                textColor={"white"}
                showMultiple={true}
                overlay={true}
                fullWidth={true}
                parallax={false}
                containerAs={"none"}
                height={550}
                gutter={false}
              />
            </OutboundLink>
          </div>
          <Grid
            columns={2}
            stackable={true}
            doubling={true}
            className={"welcome-to-grid"}
          >
            <Grid.Column className={"welcome-copy-column"}>
              <Content stackable={true} textAlign={"left"}>
                <Header as={"h1"} className={"welcome-to-header"}>
                  WELCOME TO {fishermanBusiness.name}
                </Header>
                <Header as={"h2"} className={"welcome-to-subheader"}>
                  DINE WITH US
                </Header>
                <Content.Markup
                  width={16}
                  isMarkdown={true}
                  content={fishermanBusiness.aboutMarkdown}
                />
              </Content>
              <Grid.Column width={16}>
                <CallToActionBanner stackable={true}>
                  <CallToActionBanner.CTA>
                    <Button to={"/menu/"} as={InternalLink}>
                      VIEW MENU
                    </Button>
                  </CallToActionBanner.CTA>
                  <CallToActionBanner.CTA>
                    <Call
                      phoneNumber={
                        fishermanBusiness.primaryLocation.phoneNumber
                      }
                      as={"button"}
                    />
                  </CallToActionBanner.CTA>
                </CallToActionBanner>
              </Grid.Column>
            </Grid.Column>
            <Grid.Column className={"welcome-image-column"}>
              <Image
                className={"image"}
                src={getComponentFiles({
                  components: fishermanBusinessWebsitePage.components,
                  componentName: "Content.Image",
                  componentIdentifier: "welcome",
                  numToSelect: 1,
                })}
              />
            </Grid.Column>
          </Grid>
          <div className={"image-gallery-container"}>
            <Background
              columns={1}
              stackable={true}
              className={"images-background"}
            >
              <Background.Pattern type={"CIRCLE"}>
                <OutboundLink to={"https://ashercaffe.square.site/"}>
                  <ContinuousScroll
                    className={"patterned-images-container-scroll"}
                    animationDuration={40}
                  >
                    <PatternedImageRow
                      containerAs={ContinuousScroll.Item}
                      images={getComponentFiles({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "ImageCollection",
                      })}
                    />
                  </ContinuousScroll>
                </OutboundLink>
              </Background.Pattern>
            </Background>
          </div>
          <div className={"reviews-container"}>
            <BasicCarousel
              size={3}
              pageIndicatorIcon={null}
              mobile={{ arrowsPosition: "top" }}
              items={componentIterator({
                iterator: fishermanBusiness.reviews,
                component: <Review as={"horizontalItem"} charLimit={200} />,
                propMap: { author: "author", text: "text" },
              })}
            />
          </div>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      aboutMarkdown
      primaryLocation {
        phoneNumber
      }
      reviews {
        author
        text
        link
      }
    }
    fishermanBusinessWebsitePage(
      pageType: { eq: "Home" }
      title: { eq: "Home" }
    ) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          _id
        }
      }
    }
  }
`;
